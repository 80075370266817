import React from 'react';
import {
  // FaHome,
  // FaSignInAlt,
  // FaUserAlt,
  FaBars,
  FaPowerOff,
  // FaLock,
} from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/modules/auth/actions';

import { Nav } from './styled';

export default function Header() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actions.loginFailure());
    history.push('/');
  };

  return (
    <>
      {/* <Div className=" lg:grid grid-cols-3 gap-0 select-none ">
        <Div className="p-4 md:p-10 justify-center ">
          <img
            className=" w-62 h-full md:w-46 md:h-24"
            alt=""
            src="https://lh3.googleusercontent.com/pw/AM-JKLVl1yCy02bZ-fr5qySFDPMUY_fWVXq8LCT9wXFBtnKDdgnT8N9BhuuGVrMcRwEqC3kaVOXDup0N5UdsBxS7aY96nlCgtTP_ZmUURWdSiWficpeS__Q5ASNSjcDoTWUYfKALaL5DD6w_xDFO9W-wPBS_=w807-h297-no"
          />
        </Div>
        <Div className="links">
          <p className="bottom-0 indent-2 md:bottom-20 md:left-80 text-right md:text-base leading-tight font-medium text-stone-900 hover:underline p-3">
            <br className="" />
            <br className="" />
            <em>Serviços de qualidade </em>
            <br className="links" />
            <br className="links" />
          </p>
        </Div>
        <Div className=" links absolute md:right-44 -top-1 ">
          <p className=" bottom-0 indent-2 md:bottom-20 mdleft-80 text-right md:text-base leading-tight font-medium text-stone-900 hover:underline p-3">
            <br className="links" />
            <br className="links" />
            <br className="links" />
            <br className="links" />
            <em>Residencial ou comercial</em>
          </p>
        </Div>
      </Div> */}

      {isLoggedIn ? (
        <Nav className="sticky top-0 select-none m-0 p-0  grid md:grid-cols-1  gap-2 md:h-12 bg-gray-500 rounded-lg ">
          <Link className="FaBars" to="/nav-mobile">
            <FaBars className="FaBars" size={35} />
          </Link>
          <nav className="links md:grid md:grid-cols-7 gap-2 ">
            <Link
              className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
              to="/clientes/"
            >
              <strong className="mx-2 text-center p-1 text-sm md:text-base">
                Clientes
              </strong>
            </Link>
            <Link
              className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
              to="/orcamentos/"
            >
              <strong className="mx-2 text-center p-1 text-sm md:text-base">
                Orçamentos
              </strong>
            </Link>
            <Link
              className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
              to="/orden-de-servico/"
            >
              <strong className="mx-2 text-center p-1 text-sm md:text-base">
                Orden de serviço
              </strong>
            </Link>
            <Link
              className="m-0 bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
              to="/entrada/"
            >
              <strong className="mx-2 text-center p-1 text-sm md:text-base">
                Entrada
              </strong>
            </Link>
            <Link
              className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
              to="/saida/"
            >
              <strong className="mx-2 text-center p-1 text-sm md:text-base">
                Saída
              </strong>
            </Link>
            <Link
              className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
              to="/users/"
            >
              <strong className="mx-2 text-center p-1 text-sm md:text-base">
                Users
              </strong>
            </Link>
            <span>
              {' '}
              <Link onClick={handleLogout} to="/logout">
                <FaPowerOff size={24} />
              </Link>
            </span>
          </nav>
        </Nav>
      ) : (
        <Nav className="sticky z-30 top-0 md:h-14  w-full  flex  ">
          <div className=" grid grid-cols-4 md:grid-cols-4 md:w-full  ">
            <Link className="FaBars" to="/nav-mobile">
              <FaBars className="FaBars m-2" size={32} />
            </Link>
            <div className="links flex md:ml-2 md:col-span-2 p-0 justify-start items-center content-center ">
              <img
                alt="Logo Conserte Lagos"
                className="h-8 w-8"
                src="https://lh3.googleusercontent.com/pw/AL9nZEVyWVQk2awLVmRtkPBb4DlKRm7jauLyMxc6DVpCoc1ZuUMcb2h_THM3MbRvxeP1nPJIspUyZZZu4auHkuu_H7HLSh6F5O3tu7LLUy5Vp_5Kun5v2wWKHm69eVldDQ4W6-aHnahZwbmaxJMrYpFOhKUF=s36-no"
              />
              <p className="pl-2 mt-3 text-lg text-blue-700 antialiased leading-relaxed tracking-wide font-serif font-black">
                Conserte Lagos Tecnologia{' '}
              </p>
            </div>
            <nav className=" content-center md:grid md:grid-cols-1  ">
              <div className="flex items-center content-center ">
                <Link to="/">
                  <div className="w-full h-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="blue"
                      className=" m-2 bi bi-house-fill "
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            </nav>
            {isLoggedIn ? (
              <Link onClick={handleLogout} to="/logout">
                <FaPowerOff size={24} />
              </Link>
            ) : (
              <div className="flex items-center content-center ">
                <Link className=" m-2 animate-pulse " to="/login">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="blue"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>{' '}
                </Link>
              </div>
            )}
            <div className="flex items-center content-center">
              <a
                href="https://api.whatsapp.com/send?phone=5522997681134"
                className="FaBars  m-2 animate-pulse "
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="green"
                  className="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                </svg>{' '}
              </a>{' '}
            </div>
          </div>
        </Nav>
      )}
    </>
  );
}
