import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className=" tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-32 z-20 w-full h-56 absolute ">
              <span className=" text-xl text-center m-2 md:m-0  text-white ">
                <strong>
                  CONTRATO DE MANUTENÇÃO E SERVIÇO PARA SUA EMPRESA
                </strong>
              </span>
            </div>
            <img
              alt="Manuteção de computadores"
              className="  opacity-70 p-0 w-full h-56 rounded-t-md "
              src="https://lh3.googleusercontent.com/pw/AL9nZEV-6INzH-aPb0n5iuQv4kvFQemTA6cEYL5FScE4JXXETF6Aw0PuF8dV_QgB_MHe5MoeVs8CTtBh7gD9cK-lnDQLLrHJTyxYfKCzOFlJIJxeUSdZugVKZNXYgeAEFx7gIPSzJ_PLLwLOIfv8-AyCgt9n=w870-h403-no"
            />
          </div>
          <div className=" m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0 text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA </strong>
              possui plano de fidelidade, estão inclusos: consultoria, limpeza,
              reparos, consertos e troca de peças. E além disso terá um suporte
              técnico pronto para atender as necessidades da sua empresa. A
              manutenção é fundamental para garantir o bom funcionamento,
              evitando ficar com equipamentos parados, contate nossa assistência
              técnica de informática e iremos lhe apresentar uma proposta com
              baixo custo e ótimo benefício.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
