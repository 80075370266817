import styled, { createGlobalStyle } from 'styled-components';
import '../index.css';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  .a1 {
    transition: transform 1900ms;
    -webkit-box-shadow: 0 0 2px #fff;
    box-shadow: 0 0 2px #fff;
    border-radius: 3%;
  }
  @media (min-width: 640px) {
    .a1:hover {
      -webkit-box-shadow: 0 0 9px #fff;
      box-shadow: 0 0 9px #fff;
      transition: transform 900ms;
      z-index: 1;
      transform: scale(1.1);
      margin: 0;
      white-space: normal;
      vertical-align: top;
      filter: drop-shadow(0.1px 2.5px 1.9px blue) invert(05%);
      text-decoration: blue;
      color:whitesmoke;
    }
  }

  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }
  @media (max-width: 945px) {
    .links {
      display: none;
    }
  }
  @media (min-width: 720px) {
    .descriDb {
      display: none;
    }
  }


  *::-webkit-scrollbar {
    width: 5px;

  }

  *::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    rgba(5, 5, 225, 0.2) 10%,
    rgba(5, 5, 225, 0.2) 31%,
    rgba(5, 5, 225, 0.2) 30%,
    rgba(5, 5, 225, 0.2) 20%,
    rgba(5, 5, 225, 0.2) 10%
  );
  }

  *::-webkit-scrollbar-thumb {
    background-color: linear-gradient(
    90deg,
    rgba(5, 5, 225, 10.2) 90%,
    rgba(5, 5, 225, 10.2) 31%,
    rgba(5, 5, 225, 10.2) 30%,
    rgba(5, 5, 225, 10.2) 20%,
    rgba(5, 5, 225, 10.2) 10%
  );
    border-radius: 20px;
    border: 1px solid rgba(5, 5, 225, 10.2) ;
  }

  .blocos {
    background: rgb(75, 75, 75);
  background: linear-gradient(
    90deg,
    rgba(5, 5, 225, 0.2) 60%,
    rgba(5, 5, 225, 0.2) 31%,
    rgba(5, 5, 225, 0.2) 30%,
    rgba(5, 5, 225, 0.2) 20%,
    rgba(5, 5, 225, 0.2) 10%
  );

  }

  button {
    cursor: pointer;
    background: ${colors.primaryColor};
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 700;
  }
  a {
    text-decoration: none;
    color: black;
  }

  ul {
    list-style: none;
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--info{
    background: blue;
  }
  body .Toastify .Toastify__toast-container .Toastify__toast--menu {
    background: red;
  }
  body .Toastify .Toastify__toast-container .Toastify__toast--success {
    background: ${colors.successColor}
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--error {
    background: ${colors.errorColor}
  }
`;
export const Container = styled.section`
  max-width: 480px;
  margin: 30px auto;
  padding: 30px;
  border-radius: 4px;
  background-image: url(${colors.imageBackgrudLogin});
`;
