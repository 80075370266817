/* eslint-disable no-lone-blocks */
import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import history from './services/history';
import GlobalStyle from './styles/GlobalStyles';

import Header from './components/Header';
import Base from './components/Footer';
import Carrocel from './components/carrocel';
import Routes from './routes';
import NavLeft from './components/Nav-left';
import CltStore from './components/Nav-right';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Header />
          <div className="as max-w-8xl md:mx-auto  sm:px-6 md:px-8 lg:pl-[20.5rem] lg:pr-[19.5rem]">
            <div className=" hidden lg:block fixed z-30 inset-0 top-[3.8125rem] left-[max(0px,calc(30%-45rem))] right-auto w-[19.5rem] md:pb-10 md:px-8 overflow-y-auto ">
              <div>
                <NavLeft />
              </div>
            </div>
            <div className="relative  z-10 prose prose-slate top-[0em] m-0 block dark:prose-dark">
              <div>
                <Carrocel />
              </div>
            </div>
            <div className="relative z-20 prose prose-slate mt-2 m-0 dark:prose-dark">
              <div>
                <Routes />
              </div>
            </div>
            <div className=" hidden lg:block fixed z-20 inset-0 top-[3.8125rem] right-[max(0px,calc(30%-45rem))] left-auto w-[19.5rem] pb-10 px-8 overflow-y-auto ">
              <CltStore />
            </div>
          </div>
          <div className="md:p-2 mt-2 lg:pl-[18.5rem] lg:pr-[17.5rem] ">
            <Base />
          </div>
          <GlobalStyle />
          <ToastContainer autoClose={3000} className="toast-container" />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
