import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className=" tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-3xl m-0  text-white ">
                <strong>SUPORTE EM INFORMÁTICA</strong>
              </span>
            </div>
            <div className="w-full flex justify-center ">
              <img
                alt="Suporte TI"
                className=" opacity-70 p-0 md:w-7/12  h-56 "
                src="https://lh3.googleusercontent.com/pw/AL9nZEXn1UwhA_PuY8Koh_X-QpJFkUZ-MRlhoSanOZ7kK7E8gMWq9a0Dot2tugtO1kQ1bMHlOvt6qPHw0Ia0yrQyxhKk7WHEg346dIQiOFnWnWMKqPjp7qVtf0nPvCq74DbNcOiA5f2FsM0huaSTvFhYfYwN=w600-h400-no"
              />
            </div>
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0 text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA</strong> oferece mão de obra
              especializada em Suporte de Informática. Cuida de toda a
              infraestrutura da sua empresa ou residência: computadores
              (hardware e software), rede, segurança da informação e outras
              soluções de informática importantes.
              <strong>
                <ul className="ml-6 list-disc">
                  <li> Servidores.</li>
                  <li> Computadores/Notebooks.</li>
                  <li> Equipamentos de rede: Roteadores e Switches.</li>
                  <li> Manutenção preventiva.</li>
                  <li> Soluções diárias para dúvidas e problemas.</li>
                  <li> Backup de dados.</li>
                  <li> Remoção de Vírus.</li>
                  <li> Formatação de computadores e notebooks.</li>
                </ul>{' '}
              </strong>
              Entre outros O principal objetivo do suporte em informática, é
              passar todo cuidado necessário que nossos clientes precisam tomar,
              oferecendo as melhores soluções para que seu negócio funcione de
              forma segura e estável.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
