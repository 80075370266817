import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';

export default function Carrocel() {
  return (
    <div className="mt-1 block blocos rounded-xl leading-6 tracking-wide font-sans font-bold text-yellow-700 ">
      <Carousel fade variant="dark">
        <Carousel.Item>
          <img
            className="block w-full h-56 rounded-xl"
            src="https://lh3.googleusercontent.com/pw/AMWts8BiskO2N2pCX_Pv-kmIWBbDB4QfqhWM6HdtJtXkYO2cmdmMuH-fdUllzbC0Htbm4d_xBN0Rn7HfowWolebGoRmtzQT33tmKOFfQQemuYD0RKxAly6OhCoupjg_NmPKJe_LX6Px_6VB4L3gcer8uXThr=w800-h306-no"
            alt="Serviços de suporte"
          />
          <Carousel.Caption className=" h-56 ">
            <h3 className=" text-yellow-700">TEMOS SUPORTE PARA</h3>
            <p className=" text-yellow-700">
              COMPUTADORES E NOTEBOOKS - SCANNERS
            </p>
            <p className=" text-yellow-700">
              CÂMERAS DE SEGURANÇA - IMPRESSORAS
            </p>
            <p className=" text-yellow-700">
              CENTRAL PABX - PORTEIRO ELETRÔNICO - BALANÇA
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="block w-full h-56 rounded-xl"
            src="https://lh3.googleusercontent.com/pw/AMWts8BiskO2N2pCX_Pv-kmIWBbDB4QfqhWM6HdtJtXkYO2cmdmMuH-fdUllzbC0Htbm4d_xBN0Rn7HfowWolebGoRmtzQT33tmKOFfQQemuYD0RKxAly6OhCoupjg_NmPKJe_LX6Px_6VB4L3gcer8uXThr=w800-h306-no"
            alt="Atendimento personalizado"
          />
          <Carousel.Caption className=" h-56 ">
            <h3 className=" text-yellow-700">ATENDIMENTO PERSONALIZADO</h3>
            <p className="w-full  text-yellow-700 ">
              MONTAMOS PARA SUA EMPRESA SUPORTE ADEQUADO
              <br />
              RESPEITANDO O ORÇAMENTO E GRAU DE TECNOLOGIA
              <br />
              ENTRE EM CONTATO AGORA MESMO!
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="block w-full h-56 rounded-xl"
            src="https://lh3.googleusercontent.com/pw/AMWts8BiskO2N2pCX_Pv-kmIWBbDB4QfqhWM6HdtJtXkYO2cmdmMuH-fdUllzbC0Htbm4d_xBN0Rn7HfowWolebGoRmtzQT33tmKOFfQQemuYD0RKxAly6OhCoupjg_NmPKJe_LX6Px_6VB4L3gcer8uXThr=w800-h306-no"
            alt="Contratos"
          />
          <Carousel.Caption className=" h-56 ">
            <h3 className=" text-yellow-700">CONTRATO MENSAL</h3>
            <p className=" text-yellow-700 ">
              A TERCEIRIZAÇÃO DE SERVIÇOS VIROU TENDÊNCIA
            </p>
            <p className=" text-yellow-700 ">
              SOLICITE AGORA MESMO UMA CONSULTA
            </p>
            <p className=" text-yellow-700 ">
              E ENVIAMOS A MELHOR PROPOSTA CUSTO - BENEFICIO
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
