/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';

// import { eclam } from '../../styles/GlobalStyles';



export default function Cliente() {


  return (
    <div className="m-8 p-4  grid md:grid-cols-5  gap-2 md:h-12 bg-gray-500 rounded-lg ">

      <Link
        className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
        to="/clientes/"
      >
        <strong className="mx-2">Clientes</strong>
      </Link>
      <Link
        className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
        to="/orcamentos/"
      >
        <strong className="mx-2">Orçamentos</strong>
      </Link>

      <Link
        className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
        to="/orden-de-servico/"
      >
        <strong className="mx-2">Orden de serviço</strong>
      </Link>

      <Link
        className="m-0 bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
        to="/entrada/"
      >
        <strong className="mx-2">Entrada</strong>
      </Link>

      <Link
        className="m-0  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-600  ring-0 ring-gray-600 rounded-lg static mx-0"
        to="/saida/"
      >
        <strong className="mx-2">Saída</strong>
      </Link>

    </div >
  );

}
