import React from 'react';

export default function CltStore() {
  return (
    <div className="sticky top-0 divide-y-2 ">
      <div className="">
        <a
          href="https://api.whatsapp.com/send?phone=5522997681134"
          className="flex text-xl md:text-2xl text-center font-extrabold text-green-700"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="green"
            className="bi bi-whatsapp m-2 animate-pulse font-extrabold"
            viewBox="0 0 16 16"
          >
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg>{' '}
          (22) 997681134{' '}
        </a>{' '}
      </div>
      <div>
        {' '}
        <a
          href="mailto:consertelagos@gmail.com"
          className="flex text-md p-2 font-extrabold text-blue-700"
          target="_blank"
          rel="noreferrer "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="26"
            fill="blue"
            className="bi bi-envelope-paper-fill mr-1 animate-pulse"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M6.5 9.5 3 7.5v-6A1.5 1.5 0 0 1 4.5 0h7A1.5 1.5 0 0 1 13 1.5v6l-3.5 2L8 8.75l-1.5.75ZM1.059 3.635 2 3.133v3.753L0 5.713V5.4a2 2 0 0 1 1.059-1.765ZM16 5.713l-2 1.173V3.133l.941.502A2 2 0 0 1 16 5.4v.313Zm0 1.16-5.693 3.337L16 13.372v-6.5Zm-8 3.199 7.941 4.412A2 2 0 0 1 14 16H2a2 2 0 0 1-1.941-1.516L8 10.072Zm-8 3.3 5.693-3.162L0 6.873v6.5Z"
            />
          </svg>{' '}
          consertelagos@gmail.com
        </a>{' '}
      </div>
      <div className="justify-items-center">
        <a to="/" className="text-sm text-center m-2">
          <p>Horário de funcionamento </p>
          <p>9:00 - 18:00</p>
          <p>Segunda à sexta</p>
        </a>
      </div>
      <div className="text-center pt-2">
        <iframe
          title="Conserte Lagos Tecnologia"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9623.749944831821!2d-42.013533!3d-22.83452!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x97046935c86dbd%3A0xd04c738e6bbd5437!2sEstr.%20dos%20B%C3%BAzios%2C%20715%20-%20Jardim%20Pero%2C%20Cabo%20Frio%20-%20RJ!5e1!3m2!1spt-BR!2sbr!4v1668732173845!5m2!1spt-BR!2sbr"
          className="rounded-md"
          width="250"
          height="250"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />{' '}
      </div>{' '}
    </div>
  );
}
