import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className="text-lg  tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-xl m-0  text-white ">
                <strong>FECHADURA ELETRÔNICA </strong>
              </span>
            </div>
            <div className="w-full flex justify-center ">
              <img
                alt="Fechadura eletronica"
                className="  opacity-70 p-0 w-7/12 h-56 rounded-t-md "
                src="https://lh3.googleusercontent.com/pw/AL9nZEVHKLQQZJENSkHnel9MaP99AG5FSXaGjwqtuot2cweZ_oZqUetkFF0cIsNdSzPHkAFU1e-wYLtmNmBkOHchqPATjwBC10zR2U7FbhGoUNQnPIcOLTgU27uxOExQJSGtBmQh47cI04ZfDhU538zaib54=s300-no"
              />
            </div>
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0  text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA </strong>
              trabalha com instalação de fechadura eletrônica. Uma das
              principais características desse tipo de equipamento é o
              fechamento automático das portas. Isto é, quando a porta é fechada
              por quem tem acesso, a fechadura irá travá-la automaticamente,
              impedindo que outra pessoa sem acesso passe por ela. As fechaduras
              eletrônicas são uma das opções consideradas mais eficientes para
              assegurar uma segurança realizada de maneira eficaz. Trata-se de
              um aparelho altamente seguro e que oferece, além de tudo,
              tranquilidade para o seu dia a dia.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
