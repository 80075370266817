import React from 'react';
import { Link } from 'react-router-dom';

export default function NavLeft() {
  return (
    <div className="hidden lg:block fixed z-20 inset-0 top-[3.8125rem]  right-auto w-[19.5rem] pb-10 px-8 overflow-y-auto">
      <nav id="nav" className="lg:text-sm lg:leading-6 relative">
        <div className="sticky top-0 -ml-0.5 pointer-events-none">
          <div className=" relative pointer-events-auto">
            <button
              type="button"
              className="hidden w-full lg:flex items-center text-sm leading-6 text-slate-400 rounded-t-lg ring-1 shadow-sm py-1.5 pl-2 pr-3 hover:ring-slate-300 blocos"
            >
              <svg
                width="24"
                height="24"
                fill="none"
                aria-hidden="true"
                className="mr-3 flex-none"
              >
                <path
                  d="m19 19-3.5-3.5"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  cx="11"
                  cy="11"
                  r="6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Procurar...
              <span className="ml-auto pl-3 flex-none text-xs font-semibold">
                em Conserte Lagos
              </span>
            </button>
          </div>
          <div className="h-8 bg-gradient-to-b rounded-b-lg from-white dark:from-blue-500/10" />
        </div>
        <ul>
          <li>
            <Link
              to="/Servicos"
              className="group flex items-center lg:text-sm lg:leading-6 mb-4 font-semibold text-sky-400 dark:text-sky-900"
            >
              <div className="mr-4 rounded-md ring-1 ring-slate-900/5 shadow-sm group-hover:shadow group-hover:ring-slate-900/10 dark:ring-0 dark:shadow-none dark:group-hover:shadow-none dark:group-hover:highlight-white/10 group-hover:shadow-sky-300 dark:group-hover:bg-sky-500 dark:bg-blue-500/50 dark:highlight-white/10">
                <svg className="h-6 w-6" viewBox="0 0 24 19" fill="none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    className="fill-green-400 group-hover:fill-green-500 dark:group-hover:fill-green-800/50 dark:fill-slate-100"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                  </svg>
                </svg>
              </div>
              Serviços
            </Link>
          </li>
          <li>
            <Link
              to="Contratos"
              className="group flex items-center lg:text-sm lg:leading-6 mb-4 font-semibold text-sky-400 dark:text-sky-900"
            >
              <div className="mr-4 rounded-md ring-1 ring-slate-900/5 shadow-sm group-hover:shadow group-hover:ring-slate-900/10 dark:ring-0 dark:shadow-none dark:group-hover:shadow-none dark:group-hover:highlight-white/10 group-hover:shadow-sky-300 dark:group-hover:bg-sky-900/50 dark:bg-blue-500/50 dark:highlight-white/10">
                <svg className="h-6 w-6" viewBox="0 0 16 19" fill="none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    className="fill-slate-900 group-hover:fill-slate-500 dark:group-hover:fill-slate-500 dark:fill-slate-100"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </svg>
              </div>
              Contratos
            </Link>
          </li>
          <li>
            <Link
              to="/QuemSomos"
              className="group flex items-center lg:text-sm lg:leading-6 mb-4 font-semibold text-sky-400 dark:text-sky-900"
            >
              <div className="mr-4 rounded-md ring-1 ring-slate-900/5 shadow-sm group-hover:shadow group-hover:ring-slate-900/10 dark:ring-0 dark:shadow-none dark:group-hover:shadow-none dark:group-hover:highlight-white/10 group-hover:shadow-sky-300 dark:group-hover:bg-sky-500/50 dark:bg-blue-500/50 dark:highlight-white/10">
                <svg className="h-6 w-6" viewBox="0 0 24 19" fill="none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    className="fill-fuchsia-400 group-hover:fill-fuchsia-500 dark:group-hover:fill-fuchsia-300 dark:fill-slate-100"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fillRule="evenodd"
                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    />
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>
                </svg>
              </div>
              Quem Somos
            </Link>
          </li>
          <div className=" grid grid-cols-1 gap-2">
            <iframe
              className="block w-full h-56 rounded-lg"
              width="293"
              height="290"
              src="https://www.youtube.com/embed/zIdpswzqQvg"
              title="cuidado seus dados correm perigo!"
              frameBorder="0"
              allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />{' '}
            <iframe
              className="block w-full h-56 rounded-lg"
              width="293"
              height="290"
              src="https://www.youtube.com/embed/hcAr8V3IOq4"
              title="APRESENTAÇÃO CONSERTE LAGOS"
              frameBorder="0"
              allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
          {/* <li className="mt-12 lg:mt-8">
            <h5 className="mb-8 lg:mb-3 font-semibold text-slate-900 dark:text-slate-400">
              Soluções em TI
            </h5>
            <ul className="space-y-6 lg:space-y-2 border-l border-slate-100 dark:border-slate-800">
              <li>
                <Link
                  className="block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-100 text-slate-700 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-500/75"
                  href="/docs/installation"
                  to="/"
                >
                  Sites e aplicativos
                </Link>
              </li>
              <li>
                <Link
                  className="block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-100 text-slate-700 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-500/75"
                  href="/docs/editor-setup"
                  to="/"
                >
                  Redes
                </Link>
              </li>
              <li>
                <Link
                  className="block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-100 text-slate-700 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-500/75"
                  href="/docs/using-with-preprocessors"
                  to="/"
                >
                  Software
                </Link>
              </li>
              <li>
                <Link
                  className="block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-100 text-slate-700 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-500/75"
                  href="/docs/optimizing-for-production"
                  to="/"
                >
                  Automação
                </Link>
              </li>
              <li>
                <Link
                  className="block border-l pl-4 -ml-px border-transparent hover:border-slate-900 dark:hover:border-slate-100 text-slate-700 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-500/75"
                  href="/docs/browser-support"
                  to="/"
                >
                  Cameras de segurança
                </Link>
              </li>
              <li>
                <Link
                  className="block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-100 text-slate-700 hover:text-slate-900 dark:text-slate-600 dark:hover:text-slate-500/75"
                  href="/docs/upgrade-guide"
                  to="/"
                >
                  Adequação a LGPD
                </Link>
              </li>
            </ul>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}
