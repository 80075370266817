import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className=" tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-3xl m-0  text-white ">
                <strong>MANUTENÇÃO DE COMPUTADORES</strong>
              </span>
            </div>
            <img
              alt="Manuteção de computadores"
              className="  opacity-70 p-0 w-full h-56 rounded-t-md "
              src="https://lh3.googleusercontent.com/pw/AL9nZEW0LCU3UuB8tumKnz_N1k7rC8ddDxVkZx6FTlCE7KMhqf_eVU6SJzjSKRdizHnpO-JenhicOokoK_gnXneTj-6eIoIZu3IQr19RoK_tbmaEGFatSeWbbdSDdvCDIGoiVzmgogKmwsQ1wOVkOv-ctPrt=w932-h621-no"
            />
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0  text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA</strong> oferece manutenção de
              computadores para empresas ou residências com a substituição de
              peças, upgrade, avaliação técnica no local ou suporte técnico
              remoto imediato. Nossos técnicos são treinados para lhe atender da
              melhor maneira possível, oferecendo as melhores condições e preços
              para ter um serviço em Informática com o melhor custo x benefício
              em Computadores Desktop (PC), Notebooks, Netbooks, Ultrabooks,
              Impressoras, multifuncionais, monitores etc.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
