/* eslint-disable no-unreachable */
/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import {
  FaUserCircle,
  FaEdit,
  FaWindowClose,
  FaExclamation,
} from 'react-icons/fa';

import { toast } from 'react-toastify';

// import { eclam } from '../../styles/GlobalStyles';
import { axio2 } from '../../services/axios';

import Loading from '../../components/Loading';

export default function User() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log(users);
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await axio2.get('/login-service');
      setUsers(response.data.Items);
      setIsLoading(false);
    }

    getData();
  }, []);

  const handleDeleteAsk = (e) => {
    e.preventDefault();
    const exclamation = e.currentTarget.nextSibling;
    exclamation.setAttribute('visibility', 'visible');
    e.currentTarget.remove();
  };

  const handleDelete = async (e, id, index) => {
    e.persist();

    try {
      setIsLoading(true);
      await axio2.delete(`/users/${id}`);
      const novosUsers = [...users];
      novosUsers.splice(index, 1);
      setUsers(novosUsers);
      setIsLoading(false);
    } catch (err) {
      const status = get(err, 'response.status', 0);

      if (status === 401) {
        toast.error('Você precisa fazer login');
      } else {
        toast.error('Ocorreu um erro ao excluir user');
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="mx-2 m-4 md:mx-40 grid grid-cols-1  gap-2 bg-gray-500 rounded-lg ">
      <Loading isLoading={isLoading} />

      <h1 className="p-2">Users</h1>

      <Link
        className="justify-self-end w-40 bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-500  ring-1 ring-gray-600 rounded-lg static mx-2"
        to="/user/"
      >
        <strong className="m-8">Novo user</strong>
      </Link>

      <div className="p-8 grid grid-cols-1 ">
        {users.map((user, index) => (
          <div
            className="md:p-2 m-2 grid md:grid-cols-3 md:gap-2 bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-700 hover:to-gray-500 rounded-lg"
            key={String(user.id)}
          >
            <div className="m-4 md:grid  md:grid-cols-6 md:gap-0 ">
              {get(user, 'Fotos[0].url', false) ? (
                <img src={user.Fotos[0].url} alt="" />
              ) : (
                <FaUserCircle size={36} />
              )}
              <Link to="/status" className="m-2 grid  grid-cols-0 gap-0  ">
                <span>{user.nome}</span>
              </Link>
            </div>
            <div className="m-auto grid  grid-cols-0 gap-0 ">
              <span>{user.email}</span>
            </div>

            <div className="flex justify-between md:grid m-4 md:grid-cols-3 md:gap-12  ">
              <div>
                <Link className="" to={`/user/${user.id}/edit`}>
                  <FaEdit size={16} />
                </Link>
              </div>
              <div>
                <Link onClick={handleDeleteAsk} to={`/users/${user.id}/delete`}>
                  <FaWindowClose size={16} />
                </Link>

                <FaExclamation
                  size={16}
                  visibility="hidden"
                  cursor="pointer"
                  onClick={(e) => handleDelete(e, user.id, index)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
