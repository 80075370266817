import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { isEmail, isFloat } from 'validator';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FaUserCircle, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { axio } from '../../services/axios';
import { Container } from '../../styles/GlobalStyles';
import { Form, ProfilePicture, Title } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function ExCliente({ match, history }) {
  const dispatch = useDispatch();

  const id = get(match, 'params.id', '');
  // console.log(id);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [datanacimento, setDatanacimento] = useState('');
  const [telefone, setTelefone] = useState('');
  const [endereco, setEndereco] = useState('');
  const [done, setDone] = useState('');
  const [foto, setFoto] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const headers = {
  //   Accept: 'application/json, text/plain, */*',
  //   'Content-Type': 'application/json;charset=utf-8',
  //   'User-Agent': 'axios/0.18.0',
  //   'Content-Length': 54,
  // };
  // console.log(Data);
  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(false);
        const { data } = await axio.get(`/clientes/${id}`);
        const Foto = get(data, 'Fotos[0].url', '');
        console.log(data);
        setFoto(Foto);

        setNome(data.Item.nome);
        setSobrenome(data.Item.sobrenome);
        setEmail(data.Item.email);
        setDatanacimento(data.Item.datanacimento);
        setTelefone(data.Item.telefone);
        setEndereco(data.Item.endereco);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);

        if (status === 400) errors.map((error) => toast.error(error));
        history.push('');
      }
    }

    getData();
  }, [id, history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = false;

    if (nome.length < 3 || nome.length > 255) {
      toast.error('Nome precisa ter entre 3 e 255 caracteres');
      formErrors = true;
    }

    if (sobrenome.length < 3 || sobrenome.length > 255) {
      toast.error('Sobrenome precisa ter entre 3 e 255 caracteres');
      formErrors = true;
    }

    if (!isEmail(email)) {
      toast.error('E-mail inválido');
      formErrors = true;
    }

    if (datanacimento.length < 2 || datanacimento.length > 255) {
      toast.error('Data nacimento inválida');
      formErrors = true;
    }

    if (!isFloat(String(telefone))) {
      toast.error('Telefone inválido');
      formErrors = true;
    }

    if (endereco.length < 3 || endereco.length > 255) {
      toast.error('Endereco inválida');
      formErrors = true;
    }

    if (formErrors) return;

    try {
      setIsLoading(true);
      const Item = {
        id: 15,
        done: true,
        nome,
        sobrenome,
        email,
        datanacimento,
        telefone,
        endereco,
      };

      if (id) {
        await axio.put(`/cliente/update/${id}`, Item);
        toast.success('Cliente(a) editado(a) com sucesso!');
      } else {
        const { response } = await axio.put(`/cliente`, JSON.stringify(Item));
        toast.success('Cliente(a) criado(a) com sucesso!');
        console.log(response);
        history.push(`/clientes/${response.Item.id}/edit`);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const status = get(err, 'response.status', 0);
      const data = get(err, 'response.data', {});
      const errors = get(data, 'errors', []);

      if (errors.length > 0) {
        errors.map((error) => toast.error(error));
      } else {
        toast.error('Erro desconhecido');
      }

      if (status === 401) dispatch(actions.loginFailure());
    }
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />

      <Title>{id ? 'Editar cliente' : 'Novo Cliente'}</Title>

      {id && (
        <ProfilePicture>
          {foto ? <img src={foto} alt={nome} /> : <FaUserCircle size={180} />}
          <Link to={`/fotos/${id}`}>
            <FaEdit size={24} />
          </Link>
        </ProfilePicture>
      )}

      <Form onSubmit={handleSubmit}>
        <input
          type="text"
          value={done}
          onChange={(e) => setDone(e.target.value)}
          placeholder="done"
        />
        <input
          type="text"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          placeholder="Nome"
        />
        <input
          type="text"
          value={sobrenome}
          onChange={(e) => setSobrenome(e.target.value)}
          placeholder="Sobrenome"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="text"
          value={datanacimento}
          onChange={(e) => setDatanacimento(e.target.value)}
          placeholder="Data_nacimento"
        />
        <input
          type="text"
          value={telefone}
          onChange={(e) => setTelefone(e.target.value)}
          placeholder="Telefone"
        />
        <input
          type="text"
          value={endereco}
          onChange={(e) => setEndereco(e.target.value)}
          placeholder="Endereco"
        />

        <button type="submit">Enviar</button>
      </Form>
    </Container>
  );
}

ExCliente.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape([]).isRequired,
};
