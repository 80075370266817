// http://138.2.245.111
// https://g6l72hvty0.execute-api.us-east-2.amazonaws.com/v1/apicltdb

import axios from 'axios';

// axios.defaults.headers.post['Content-Type'] = `text/json`;
export const axio = axios.create({
  baseURL: 'https://g6l72hvty0.execute-api.us-east-2.amazonaws.com',
});

export const axio2 = axios.create({
  baseURL: 'https://qj8nl92dvb.execute-api.us-east-2.amazonaws.com/CLT',
});
