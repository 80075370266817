/* eslint-disable no-unreachable */
/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// import { eclam } from '../../styles/GlobalStyles';
import { FaSistrix, FaRegCheckCircle } from 'react-icons/fa';
import { axio } from '../../services/axios';

import Loading from '../../components/Loading';

export default function Cliente() {
  const [item, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log(item);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await axio.get('/clientes');
      setItems(response.data.Items);
      setIsLoading(false);
    }

    getData();
  }, []);

  return (
    <div className="rounded-xl grid grid-cols-1 pt-0 gap-1 p-1 text-justify dark:bg-slate-300 box-content  h-auto border-0 ">
      <Loading isLoading={isLoading} />

      <h1 className="p-2">Clientes</h1>

      <Link
        className="justify-self-end  bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-500  ring-1 ring-gray-600 rounded-lg static mx-2"
        to="/cliente"
      >
        <strong className="m-2">Novo Cliente</strong>
      </Link>

      <div className="sticky z-30 top-12 text-sm text-center links md:divide-x blocos grid grid-cols-5 w-full gap-1 bg-gradient-to-r divide-x from-gray-400 to-gray-400 rounded-lg">
        <div className=" grid grid-cols-2 gap-1 ">
          <div className="cursor-pointer animate-pulse m-2 p-2 ">
            <FaSistrix size={32} color="white" />
          </div>
          <h1 className="m-1 p-2 w-8">
            {' '}
            <strong>ID</strong>
          </h1>
        </div>
        <h1 className="m-1 p-2">
          {' '}
          <strong>Nomes</strong>
        </h1>
        <h1 className="m-1 p-2">
          {' '}
          <strong>Contato</strong>
        </h1>
        <h1 className="m-1 p-2">
          {' '}
          <strong>Criado em</strong>
        </h1>
        <h1 className="m-1 p-2">
          {' '}
          <strong>Modifição</strong>
        </h1>
      </div>
      <div className="grid gap-1">
        {item.map((Items) => (
          // console.log(Items.name),
          <div
            className=" md:border-y-0 md:border-x-1 border border-slate-200/25 font-sans grid grid-cols-2 text-sm md:grid-cols-5 w-full   bg-gradient-to-r from-gray-400/25 to-gray-400 hover:from-gray-700/25 hover:to-gray-500/25 rounded-lg"
            key={String(Items.id)}
          >
            <div className=" grid grid-cols-2 gap-1 ">
              <div className="cursor-pointer m-2 p-2 ">
                <FaRegCheckCircle />
              </div>
              <div className=" md:border-y-0 md:border-x-1 text-center border border-slate-200/25 m-2 p-2 whitespace-normal">
                <h1 className="descriDb text-center  m-1 p-2 w-8">
                  {' '}
                  <strong>ID</strong>
                </h1>{' '}
                {Items.id}
              </div>
            </div>
            <div className="md:border-y-0 md:border-x-1 border border-slate-200/25  m-2 p-2 whitespace-normal">
              <h1 className="descriDb m-1 p-2 w-8">
                {' '}
                <strong>Nome</strong>
              </h1>{' '}
              {Items.name}
            </div>
            <div className=" md:border-y-0 md:border-x-1 border border-slate-200/25 m-2 p-2 whitespace-normal">
              <h1 className="descriDb m-1 p-2 w-8">
                {' '}
                <strong>Contato</strong>
              </h1>{' '}
              {Items.telefone}
            </div>
            <div className="  md:border-y-0 md:border-x-1 border border-slate-200/25 m-2 p-2 whitespace-normal">
              <h1 className="descriDb m-1 p-2 font-extrabold ">
                {' '}
                <strong>Criado em</strong>
              </h1>{' '}
              {Items.created_at}
            </div>
            <div className="md:border-y-0 md:border-x-1 border border-slate-200/25 m-2 p-2 text-sm  whitespace-normal">
              <h1 className="descriDb m-1 p-2 w-8">
                {' '}
                <strong>Modifição</strong>
              </h1>{' '}
              {Items.updated_at}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
