import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className="text-lg  tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-3xl m-0  text-white ">
                <strong>SEGURANÇA DA INFORMAÇÃO</strong>
              </span>
            </div>
            <img
              alt="Manuteção de computadores"
              className="  opacity-70 p-0 w-full h-56 rounded-t-md "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXJ89p9AcibMXUGRD3gW2bCTqj2jVQ9BaRIw6ZDOgbBvVyJn_yr_DYoYrKjLE5nkFHrwejpscVD0M2pEVSWtVfFMImo2BUCkBV67kXh9tqnr5FE5GPHqn1fyhyAbFhbEvTboaVSNQHnWUWbGHFFlblf=w658-h376-no"
            />
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0  text-base md:text-lg">
              Nós da <strong>CONSERTE LAGOS TECNOLOGIA</strong> zelamos em
              manter os seus dados seguros, seja contra colaboradores
              mal-intencionados, infecção por vírus de computador e ataques de
              hackers. Segurança da informação é um dos principais assuntos a
              serem abordados em qualquer negócio, pois envolve além da
              segurança dos dados de uma empresa como também de pessoas. Qual é
              o conceito de segurança da informação? Segurança da informação é a
              proteção de dados de propriedade das organizações contra ameaças
              diversas. Trata-se de um esforço pautado por ações que objetivam
              mitigar riscos e garantir a continuidade das operações.
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
