/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-absolute-path

export const primaryColor = '#990000';
export const primaryDarkColor = '#fff';

export const successColor = '#0197F6';
export const infoColor = '#0197F6';
export const errorColor = '#F2AF29';
export const warningColor = '#F2AF29';
export const imageBackgrudLogin =
  'https://lh3.googleusercontent.com/pw/AM-JKLWMxR79dczH-6K4JUosgeXcvQwugl6bVqpWSw5qupIGE-hdkGqwou_2Yv-Bxorwho4SEnTy1dS2q0BHWFrbCcJ4Th8peO1rrXn4ImJdXMNkJL_O7le8akU0OXI_xIDwRq5yEZHYCI_t5yuGmNON3mkH=w1055-h598-no';
