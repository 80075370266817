import styled from 'styled-components';

export const Nav = styled.nav`
  background: rgb(75, 75, 75);
  background: linear-gradient(
    90deg,
    rgba(5, 5, 225, 0.2) 60%,
    rgba(5, 5, 225, 0.2) 31%,
    rgba(5, 5, 225, 0.2) 30%,
    rgba(5, 5, 225, 0.2) 20%,
    rgba(5, 5, 225, 0.2) 10%
  );

  a {
    color: #1c1917;
    /* margin: 0 10px 0 0; */
    font-weight: bold;
    /* padding: 1px; */
    /* font-size: 1.2em; */
    transition: all 600ms;
  }
  a:hover {
    filter: drop-shadow(0.1px 2.5px 1.9px blue) invert(05%);
    text-decoration: blue;
  }
  @media (max-width: 788px) {
    a {
      /* display: none; */
      /* padding: 15px; */
    }
  }
  @media (max-width: 945px) {
    .links {
      display: none;
    }
  }
  @media (min-width: 945px) {
    .FaBars {
      display: none;
    }
  }
  .FaBars {
    color: #1c1917;

    padding: 1px;
    font-size: 2em;
  }

  .logo {
    font-size: 1.6em;
    font-weight: 400;
    /* padding-right: 80px; */
  }
  @media (max-width: 788px) {
    .logo {
      padding-right: 0px;
      font-size: 1.3em;
    }
  }
`;
export const links = styled.nav`
  .pub {
    width: 100%;
    height: 80px;
  }
  .publi {
    width: 100%;
    height: 180px;
  }
  p {
    color: 0, 0, 0;
    font-weight: 800;

    font-size: 1.6em;

    width: 100%;
    height: 180px;
  }
  @media (max-width: 945px) {
    .links {
      display: none;
    }
  }
`;
