import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className="text-lg  tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-xl m-0  text-white ">
                <strong>CENTRAL PABX</strong>
              </span>
            </div>
            <img
              alt="Manuteção de computadores"
              className="  opacity-70 p-0 w-full h-56 rounded-t-md "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXzWaorNULwHDdilesq1LUNufuffbiVuIulORDmabp3cw2fSp4GKEZabS2VC9ALsw4ViF9qwDnhws-B7ffGg1-wzC83fEq6M_z91YhZZSRuZP9cfcM_OXlAGcKCAfWEuT4JSBD_Brn3HwYNCmUzCuI1=w415-h258-no"
            />
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0  text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA </strong>
              oferece o mais moderno sistema de PABX. Um sistema PABX é um
              equipamento presente em diversas empresas e escritórios que têm a
              necessidade de conectar ambientes por linha telefônica. Estes
              aparelhos aperfeiçoam o sistema de comunicação, melhorando o
              atendimento e a comunicação corporativos, permitindo maior
              organização no ambiente profissional. Ao instalar o dispositivo,
              pode-se dar um destino correto às ligações, implantando um ramal
              por setor, ou ainda, otimizar o trabalho, realizando chamadas
              simultâneas, por exemplo. O que é um sistema PABX? O PABX é uma
              central telefônica. De um modo simplificado, o aparelho conecta-se
              diretamente à uma operadora de telefonia (com uma ou mais linhas),
              da mesma maneira que um telefone comum. O diferencial é que ele
              permite a conexão direta com outras linhas internas, inclusive sem
              fio, por meio de ramais. Assim, de forma automática (após a
              configuração feita pelo usuário) e sem a intervenção de um
              operador ou da telefonista, este equipamento direciona a chamada
              para o ramal escolhido.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
