/* eslint-disable no-unreachable */
/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import {
  FaUserCircle,
  FaEdit,
  FaWindowClose,
  FaExclamation,
} from 'react-icons/fa';

import { toast } from 'react-toastify';
// import { eclam } from '../../styles/GlobalStyles';
import { axio } from '../../services/axios';

import Loading from '../../components/Loading';

export default function Cliente() {
  const [clientes, setClientes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log(clientes);
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await axio.get('/clientes');
      setClientes(response.data);
      setIsLoading(false);
    }

    getData();
  }, []);

  const handleDeleteAsk = (e) => {
    e.preventDefault();
    const exclamation = e.currentTarget.nextSibling;
    exclamation.setAttribute('visibility', 'visible');
    e.currentTarget.remove();
  };

  const handleDelete = async (e, id, index) => {
    e.persist();

    try {
      setIsLoading(true);
      await axio.delete(`/clientes/${id}`);
      const novosClientes = [...clientes];
      novosClientes.splice(index, 1);
      setClientes(novosClientes);
      setIsLoading(false);
    } catch (err) {
      const status = get(err, 'response.status', 0);

      if (status === 401) {
        toast.error('Você precisa fazer login');
      } else {
        toast.error('Ocorreu um erro ao excluir cliente');
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="mx-2 m-4 md:mx-40 grid grid-cols-1  gap-2 bg-gray-500 rounded-lg ">
      <Loading isLoading={isLoading} />

      <h1 className="p-2">Clientes</h1>

      <Link
        className="justify-self-end w-40 bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-500 hover:to-gray-500  ring-1 ring-gray-600 rounded-lg static mx-2"
        to="/cliente/"
      >
        <strong className="m-8">Novo cliente</strong>
      </Link>

      <div className="p-0 grid grid-cols-1 ">
        {clientes.map((cliente, index) => (
          <div
            className="md:p-2 m-2 grid md:grid-cols-3  md:gap-2 bg-gradient-to-r from-gray-300 to-gray-400 hover:from-gray-700 hover:to-gray-500 rounded-lg"
            key={String(cliente.id)}
          >
            <div className="ml-2 m-2 md:grid  md:grid-cols-6  md:gap-0">
              {get(cliente, 'Fotos[0].url', false) ? (
                <img src={cliente.Fotos[0].url} alt="" />
              ) : (
                <FaUserCircle size={36} />
              )}
              <Link to="/status" className=" grid  grid-cols-0 gap-0  ">
                <span>{cliente.nome}</span>
              </Link>
            </div>
            <div className="ml-2 grid  grid-cols-0 gap-0 ">
              <span>{cliente.email}</span>
            </div>

            <div className=" flex justify-between md:grid m-4 md:grid-cols-3 md:gap-12">
              <div>
                <Link className="" to={`/cliente/${cliente.id}/edit`}>
                  <FaEdit size={16} />
                </Link>
              </div>
              <div>
                <Link
                  onClick={handleDeleteAsk}
                  to={`/cliente/${cliente.id}/delete`}
                >
                  <FaWindowClose size={16} />
                </Link>

                <FaExclamation
                  size={16}
                  visibility="hidden"
                  cursor="pointer"
                  onClick={(e) => handleDelete(e, cliente.id, index)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
