/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-multi-assign */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
// import img from 'react -img';
// import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import history from '../../../services/history';

export default function Bloco1() {
  // const handleA = (e) => {
  //   e.preventDefault();
  //   toast.error('voce nao ta logado');
  //   history.push('/animais/OsCaesConsideradosComoOsMaioresDoMundo');
  //   window.scrollTo(0, 0);
  // };
  return (
    <>
      <div className=" ">
        <figure className="rounded-xl grid grid-cols-1  gap-2   h-auto pb-2 border-0 ">
          <div className="box-decoration-slice bg-gradient-to-t  pl-8 from-gray-500 to-slate-100 justify-center rounded-lg  flex items-center select-none p-0">
            <p className="break-words  select-none leading-relaxed tracking-wide indent-4">
              <strong>SERVIÇOS</strong>
            </p>
          </div>
          <div className="font-medium grid grid-cols-1  gap-px  pt-0 text-neutral-600 md:p-0 space-y-2">
            <div className="box-decoration-slice border-b-0 border-t-0 bg-gradient-to-r  from-gray-300 to-slate-500 rounded-lg  flex items-center select-none p-0">
              <img
                className="w-64 p-8 overflow-hidden   flex rounded-2 place-self-end select-none"
                src="https://lh3.googleusercontent.com/pw/AM-JKLX2Gx9XeoUjQ1N-3_UNvWR5qYMXArFb9467pVtxY_E0EjsPWZN2MDfjcbebZNAuti1pAxzSGQ7ekpyacucuxKjYrDFVps4tbXpZiCpScLpX16tvVTxB5r3ViRt_FTcwXro7BneRqLQurvFkF36pvGFI=w387-h255-no"
                alt=""
              />
              <p className="text-base md:text-2xl  break-words w-4/5  tracking-wide leading-relaxed p-2 indent-4">
                <br />
                <em>
                  Suporte e manutenção de computadores, notebooks, impressoras,
                  scanners. Limpeza, Segurança e configuração.
                </em>
              </p>
            </div>
            <div className="box-decoration-slice bg-gradient-to-l border-b-0 border-t-0 from-gray-300 to-slate-500 rounded-lg  flex items-center select-none p-0">
              <p className="text-base md:text-2xl  break-words w-4/5  tracking-wide leading-relaxed p-2 indent-4">
                <br />
                <em>
                  Criação de sites, Aplicativos e blogs. Catálogos virtuais,
                  criação de Logomarca.
                </em>
              </p>
              <img
                className="w-64 p-8 overflow-hidden   flex rounded-2 place-self-end select-none"
                src="https://lh3.googleusercontent.com/pw/AM-JKLVxsQPn8q3CHI-9i_C-aacU50nN55-VhAw_KpKbwC0BHbRxOoBYYzSDce_Jt3w_qLfAS_p16TZeprXTGzegbdjSvJUCj8w6AzFfxSBtF9gCmD_SkC8emoBJW3jK2AOlwD48fBffoMcJC-6iv0rkgXLR=w339-h290-no"
                alt=""
              />
            </div>
            <div className="box-decoration-slice bg-gradient-to-r border-b-0 border-t-0 from-gray-300 to-slate-500 rounded-lg  flex items-center select-none p-0">
              <img
                className="w-64 p-8 overflow-hidden   flex rounded-2 place-self-end select-none"
                src="https://lh3.googleusercontent.com/pw/AM-JKLUx6vIb973GSrYxAmIznARA7XfYyNG29UTX5gZ3O91hvkXilbS9IXrd91YoUd9k-iN7XK51ThWe0lAnMq7FdBrVSIbQ6BvWCnnYifoe_55itIxNXU_gYHoRtO55OB10FWZqvVCY_W6M732aplO6iNSX=w369-h295-no"
                alt=""
              />
              <p className="text-base md:text-2xl  break-words w-4/5  tracking-wide leading-relaxed p-2 indent-4">
                <br />
                <em>
                  Planejamento de redes de monitoramento. DVRS e CFTV,
                  implantação, configuração e manutenção de sistemas de
                  vigilância com acesso remoto e armazenamento físico ou em
                  nuvem.
                </em>
              </p>
            </div>
            <div className="box-decoration-slice bg-gradient-to-l mb-1 border-b-0 border-t-0 from-gray-300 to-slate-500 rounded-lg  flex items-center select-none p-8">
              <p className="text-base md:text-2xl  break-words w-4/5  tracking-wide leading-relaxed p-2 indent-4">
                <br />
                <em>
                  Central PABX, porteiro eletrônico, serviço de cabeamento,
                  central de TV e WiFi.
                </em>
              </p>
              <img
                className="w-64 p-8 overflow-hidden   flex rounded-2 place-self-end select-none"
                src="https://lh3.googleusercontent.com/pw/AM-JKLVunYhv6m3IYId9m_qPTH-odTZhXDBgbQi1MKNCy0TSdhWlyQ6CvJeqAUMVaObcUUaudSvbycmnRjhaas8G1w44SZcjoRRjO7gOrtOti7OoneAyIjjXXUQ5kDy_tvyk7S9rGkbBpxexDzN_HoiGH4UY=w317-h255-no"
                alt=""
              />
            </div>
          </div>
        </figure>
      </div>
    </>
  );
}
