import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  return (
    <div className="text-lg  tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-0 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex rounded-md ">
            {/* <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-xl m-0  text-white ">
                <strong>
                  CONTRATO DE MANUTENÇÃO DE SERVIÇO PARA SUA EMPRESA
                </strong>
              </span>
            </div> */}
            <img
              alt="Manuteção de computadores"
              className="  opacity-100 p-0 w-full h-56 rounded-t-md "
              src="https://lh3.googleusercontent.com/pw/AL9nZEU1poP--Vh8xA5JEgxOnW4l5miyrLrf4voaKAK4umugNCGlKZC-VdiKkD94mRB2BvEFml9TD-Z2UiuSMEf4Qi0G3vATsdmOHKXi02IV0g8ioi2ry1bCOflOswvktGrrp7xdk-S4w4yWImK9zYrPgXGr=w1300-h489-no"
            />
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0 text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA </strong>
              atua no mercado desde 2019, empresa pronta para enfrentar os
              desafios tecnológicos e fornecer o melhor atendimento que nossos
              clientes merecem. Com ética nos relacionamentos, transparência nos
              negócios e comprometimento profissional, a nossa estratégia é
              superar as expectativas de nossos clientes, oferecendo a eles um
              preço justo e um atendimento excepcional em busca de resultados e
              sucesso com soluções cabíveis e inovadoras em diversas
              plataformas. As tendências do mercado de TI, se faz necessário
              contar com uma empresa terceirizada de TI que seja confiável,
              responsável, que estampe em seus trabalhos, eficiência, qualidade
              e principalmente agilidade, proporcionando a seus clientes um
              atendimento personalizado, de modo que atenda de forma única a
              toda sua estrutura organizacional. Temos por missão atender de
              forma rápida e transparente as necessidades de nossos clientes,
              seja presencial ou remotamente. Garantindo um serviço de qualidade
              e competência.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato agora mesmo e obtenha o melhor suporte da
                  região{' '}
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
