import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className="text-lg  tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-xl m-0  text-white ">
                <strong>PORTEIRO ELETRÔNICO </strong>
              </span>
            </div>
            <img
              alt="Manuteção de computadores"
              className="  opacity-70 p-0 w-full h-56 rounded-t-md "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXK6tvzemqngUjayjOA8A5b_ezqByfGFemHaNf760TxNo8o9UtgnMRhbvP9llPRXYGqrDx9EoNLGyMdIS6P0Lj92gU2UgCZVLwRQBmzJOCanP2J3oHZLSn9UdEXwFP2G-9Uj4hggxK3pBzDZ1gaojFj=w646-h441-no"
            />
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0  text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA </strong>
              trabalha com instalação de porteiro eletrônico. O porteiro
              eletrônico exerce funções de atender chamadas de portas e portões
              externos sem sair de dentro de casa e liberar a entrada dos
              visitantes com grande praticidade. <br />
              <strong className="indent-8  ml-4">
                A importância do sistema de segurança eletrônica
              </strong>{' '}
              <br />
              Estamos vivendo em um mundo acelerado que contém muitas
              comodidades modernas fornecidas pela tecnologia. O sistema de
              segurança eletrônica é uma delas, e já provaram ser um benefício
              efetivo para a segurança doméstica ou corporativa de qualquer
              localidade devido à propensão ao crime. Esse instrumento não
              apenas permite que as pessoas reforcem as medidas de segurança das
              casas, mas também evitem visitantes indesejados. Os sistemas de
              segurança eletrônica e intercomunicação estão disponíveis em
              vários modelos, desde os mais simples dos dispositivos até aqueles
              com completa funcionalidade, normalmente mais complexos. Eles
              devem ser instalados junto com a instalação de campainhas (que
              também contam com vasta gama de opções). Dependendo do seu
              objetivo e orçamento que disponibiliza, você pode instalar um
              sistema de segurança eletrônica com fio ou sem fio, já que ambos
              são capazes de atender às suas necessidades.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
