import React from 'react';
import { Switch } from 'react-router-dom';

import MyRoute from './MyRoute';
import Home from '../pages/Home';
import NavMobile from '../components/Nav-Mobile';
import Users from '../pages/Users';
import User from '../pages/User';
import Cliente from '../pages/Cliente';
import ExCliente from '../pages/ExCliente';
import Clientes from '../pages/Clientes';
import ExClientes from '../pages/Ex-Clientes';
import Status from '../pages/Status';
import Fotos from '../pages/Fotos';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Contratos from '../pages/content/contratos';
import Servicos from '../pages/Servicos';
import QuemSomos from '../pages/QuemSomos';
import Manutencao from '../pages/content/manutencao';
import Suporte from '../pages/content/suporte';
import Seguranca from '../pages/content/seguranca';
import Desenvolvimento from '../pages/content/desenvolvimento';
import Camerasseguranca from '../pages/content/camerasseguranca';
import Centralpabx from '../pages/content/centralpabx';
import Porteiroeletronico from '../pages/content/porteiroeletronico';
import Fechaduraeletronica from '../pages/content/fechaduraeletronica';
import Centralalarme from '../pages/content/centralalarme';
import Page404 from '../pages/Page404';

export default function Routes() {
  return (
    <Switch>
      <MyRoute exact path="/" component={Home} isClosed={false} />
      <MyRoute
        exact
        path="/nav-mobile"
        component={NavMobile}
        isClosed={false}
      />
      <MyRoute exact path="/Servicos" component={Servicos} isClosed={false} />
      <MyRoute exact path="/Contratos" component={Contratos} isClosed={false} />
      <MyRoute
        exact
        path="/Manutencao"
        component={Manutencao}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/suporteInformatica"
        component={Suporte}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/segurancaDaInformacao"
        component={Seguranca}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/desenvolvimentoAppsESites"
        component={Desenvolvimento}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/camerasseguranca"
        component={Camerasseguranca}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/centralpabx"
        component={Centralpabx}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/porteiroeletronico"
        component={Porteiroeletronico}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/fechaduraeletronica"
        component={Fechaduraeletronica}
        isClosed={false}
      />
      <MyRoute
        exact
        path="/centralalarme"
        component={Centralalarme}
        isClosed={false}
      />
      <MyRoute exact path="/QuemSomos" component={QuemSomos} isClosed={false} />
      <MyRoute exact path="/login" component={Login} isClosed={false} />
      <MyRoute exact path="/register" component={Register} isClosed />
      <MyRoute exact path="/clientes" component={Clientes} isClosed />
      <MyRoute exact path="/ExClientes" component={ExClientes} false />
      <MyRoute exact path="/users" component={Users} isClosed={false} />
      <MyRoute exact path="/status" component={Status} isClosed />

      <MyRoute exact path="/cliente/:id/edit" component={Cliente} isClosed />
      <MyRoute exact path="/user/:id/edit" component={User} isClosed />
      <MyRoute exact path="/cliente/" component={Cliente} isClosed />
      <MyRoute exact path="/Excliente/" component={ExCliente} isClosed />
      <MyRoute exact path="/user/" component={User} isClosed />
      <MyRoute exact path="/fotos/:id" component={Fotos} isClosed />

      <MyRoute path="*" component={Page404} />
    </Switch>
  );
}
