import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className="text-lg  tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-xl m-0  text-white ">
                <strong>CÂMERAS DE SEGURANÇA “CFTV”</strong>
              </span>
            </div>
            <img
              alt="Manutençao de camera"
              className="  opacity-70 p-0 w-full h-56 rounded-t-md "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXJGNE3ceq_91pe6WgzPgGMuthvbKcchdeg9ANgkaaoHz9GfAaKCSvmXRsBnNfVFy2Id1BfKwVaH3c9FXKTNOfCbegZrThgiz4KfLcsCBObtc7_H91CQwzD6hANhh3hSIXd1PT8eegHTRjU9EE_p-w2=w659-h371-no"
            />
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0  text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA </strong>
              trabalha com instalações de câmeras de segurança, que é um
              equipamento fundamental que monitora os ambientes comuns dos
              condomínios ou empresas registrando e gravando ações criminosas ou
              ações administrativas, podendo ser vistas em tempo real ou
              posteriormente. Porque utilizar câmeras de segurança?
              <ul className="ml-6 list-disc">
                <li>Monitoramento 24h por dia;</li>{' '}
                <li>Controle de entrada e saída de pessoas;</li>
                <li>Conectividade com redes;</li>
                <li>Segurança.</li>{' '}
              </ul>
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
