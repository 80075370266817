import React from 'react';
import { Link } from 'react-router-dom';
import './styled';
// import { toast } from 'react-toastify';

export default function Home() {
  // window.onload = toast.info('Bem vindo!');
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className="text-lg pt-0 tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="pt-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-2 xl:grid-cols-3 ">
          <Link
            to="/manutencao"
            className=" a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="Manuteção de computadores"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEW0LCU3UuB8tumKnz_N1k7rC8ddDxVkZx6FTlCE7KMhqf_eVU6SJzjSKRdizHnpO-JenhicOokoK_gnXneTj-6eIoIZu3IQr19RoK_tbmaEGFatSeWbbdSDdvCDIGoiVzmgogKmwsQ1wOVkOv-ctPrt=w932-h621-no"
            />
            <h1 className="">
              <p className="text-center text-sm break-all m-1">
                <strong>MANUTENÇÃO DE COMPUTADORES</strong>
              </p>
              {/* <br /> A CONSERTE LAGOS TECNOLOGIA oferece manutenção de
              computadores para empresas ou residências com a substituição de
              peças, upgrade, avaliação técnica no local ou suporte técnico
              remoto imediato. Nossos técnicos são treinados para lhe atender da
              melhor maneira possível, oferecendo as melhores condições e preços
              para ter um serviço em Informática com o melhor custo x benefício
              em Computadores Desktop (PC), Notebooks, Netbooks, Ultrabooks,
              Impressoras, multifuncionais, monitores etc. Entre em contato e
              saiba mais. */}
            </h1>
          </Link>

          {/* <img
            alt="desk-top e notbook"
            className="w-32 h-32 md:w-60 md:mr-8 ml-2 float-right"
            src="https://lh3.googleusercontent.com/pw/AM-JKLXqg_h4KfVm2YZ6uQFEtQrGupM4i4HE6SbUe1WlyQCxV3IeKU18GmkYRShop6TWso9Xs3vhNCb3BNqy_FLX1sRNgl7H6PlVFeqrBwm33E2MrcpeBhTd1EWP1ZzuKwm-N7O0KsyQsbczogT5Khp_RSQX=w1010-h758-no"
          /> */}
          <Link
            to="/suporteInformatica"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="Office"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXn1UwhA_PuY8Koh_X-QpJFkUZ-MRlhoSanOZ7kK7E8gMWq9a0Dot2tugtO1kQ1bMHlOvt6qPHw0Ia0yrQyxhKk7WHEg346dIQiOFnWnWMKqPjp7qVtf0nPvCq74DbNcOiA5f2FsM0huaSTvFhYfYwN=w600-h400-no"
            />
            <h1 className="">
              <p className="text-center text-sm break-all m-1">
                <strong>SUPORTE EM INFORMÁTICA</strong>
              </p>
              {/* <br />A CONSERTE LAGOS TECNOLOGIA oferece mão de obra
              especializada em Suporte de Informática. Cuida de toda a
              infraestrutura da sua empresa ou residência: computadores
              (hardware e software), rede, segurança da informação e outras
              soluções de informática importantes.
              <ul className="ml-6 list-disc">
                <li> Servidores.</li>
                <li> Computadores/Notebooks.</li>
                <li> Equipamentos de rede: Roteadores e Switches.</li>
                <li> Manutenção preventiva.</li>
                <li> Soluções diárias para dúvidas e problemas.</li>
                <li> Backup de dados.</li>
                <li> Remoção de Vírus.</li>
                <li> Formatação de computadores e notebooks.</li>
              </ul>{' '}
              Entre outros O principal objetivo do suporte em informática, é
              passar todo cuidado necessário que nossos clientes precisam tomar,
              oferecendo as melhores soluções para que seu negócio funcione de
              forma segura e estável. Entre em contato e saiba mais. */}
            </h1>
          </Link>
          <Link
            to="segurancaDaInformacao"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="SEGURANÇA DA INFORMAÇÃO"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXJ89p9AcibMXUGRD3gW2bCTqj2jVQ9BaRIw6ZDOgbBvVyJn_yr_DYoYrKjLE5nkFHrwejpscVD0M2pEVSWtVfFMImo2BUCkBV67kXh9tqnr5FE5GPHqn1fyhyAbFhbEvTboaVSNQHnWUWbGHFFlblf=w658-h376-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>SEGURANÇA DA INFORMAÇÃO</strong>
            </p>
            <h1 className="">
              {/* Nós da CONSERTE LAGOS TECNOLOGIA zelamos em manter os seus dados
              seguros, seja contra colaboradores mal-intencionados, infecção por
              vírus de computador e ataques de hackers. Segurança da informação
              é um dos principais assuntos a serem abordados em qualquer
              negócio, pois envolve além da segurança dos dados de uma empresa
              como também de pessoas. Qual é o conceito de segurança da
              informação? Segurança da informação é a proteção de dados de
              propriedade das organizações contra ameaças diversas. Trata-se de
              um esforço pautado por ações que objetivam mitigar riscos e
              garantir a continuidade das operações. Entre em contato e saiba
              mais. */}
            </h1>
          </Link>
          <Link
            to="desenvolvimentoAppsESites"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="DESENVOLVIMENTO DE SITES E APPS"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEU_RTbCjLzUBzX6OufiRNKY0vbQR8KQ0wV5cHvX7t-MYmpSr70pUwmimfcQFsvxiPVoLbtgfWc1GbfKkfwramF6Pchf_PmjUHNcxX9dyBTUJdX3bg2loXPcIEDb0Wu0T_Xq5rfEJijTXvZz10FfhnU3=w657-h412-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>DESENVOLVIMENTO DE SITES E APPS</strong>
            </p>
            <h1 className="">
              {/* A CONSERTE LAGOS TECNOLOGIA acredita que atualmente, o website é
              um cartão de visita importantíssimo para qualquer empresa. A
              criação de site ou APP, tem o propósito de trazer novos clientes e
              valor agregado para sua empresa ou negócio, tornando mais
              competitivo e mais atualizado em relação aos clientes e
              consumidores do mercado moderno, afinal, hoje em dia a maioria das
              pessoas faz buscas na internet sobre a empresa, produto ou serviço
              que está prestes a contratar. Essa é a forma correta para se obter
              sucesso em vendas com um site institucional ou um site de vendas e
              de propagar sua marca pela internet. Entre em contato e saiba
              mais. */}
            </h1>
          </Link>
          <Link
            to="contratos"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="CONTRATOS"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEV-6INzH-aPb0n5iuQv4kvFQemTA6cEYL5FScE4JXXETF6Aw0PuF8dV_QgB_MHe5MoeVs8CTtBh7gD9cK-lnDQLLrHJTyxYfKCzOFlJIJxeUSdZugVKZNXYgeAEFx7gIPSzJ_PLLwLOIfv8-AyCgt9n=w870-h403-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>CONTRATOS</strong>
            </p>
          </Link>
          <Link
            to="camerasseguranca"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="manutençao de cameras"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXJGNE3ceq_91pe6WgzPgGMuthvbKcchdeg9ANgkaaoHz9GfAaKCSvmXRsBnNfVFy2Id1BfKwVaH3c9FXKTNOfCbegZrThgiz4KfLcsCBObtc7_H91CQwzD6hANhh3hSIXd1PT8eegHTRjU9EE_p-w2=w659-h371-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>CAMERAS DE SEGURANÇA</strong>
            </p>
          </Link>
          <Link
            to="/centralpabx"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="centralpabx"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXzWaorNULwHDdilesq1LUNufuffbiVuIulORDmabp3cw2fSp4GKEZabS2VC9ALsw4ViF9qwDnhws-B7ffGg1-wzC83fEq6M_z91YhZZSRuZP9cfcM_OXlAGcKCAfWEuT4JSBD_Brn3HwYNCmUzCuI1=w415-h258-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>CENTRAL PABX</strong>
            </p>
          </Link>
          <Link
            to="porteiroeletronico"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="porteiroeletronico"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXK6tvzemqngUjayjOA8A5b_ezqByfGFemHaNf760TxNo8o9UtgnMRhbvP9llPRXYGqrDx9EoNLGyMdIS6P0Lj92gU2UgCZVLwRQBmzJOCanP2J3oHZLSn9UdEXwFP2G-9Uj4hggxK3pBzDZ1gaojFj=w646-h441-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>PORTEIRO ELETRÔNICO </strong>
            </p>
          </Link>
          <Link
            to="centralalarme"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="centralalarme"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEXUYhISPN9N0jheU8IvDpQk53PWkdqITbKZypFNiF7z0U19hPxZgZNUn0DahsTQQk7fIQtyI0tuv5foeX8wjE4ALrdgz-wzQ9kgpaWfwmU17BH1KSBfA4GTLeryHdPTQvE7ApQWlmccWuCNJ7cf9ts6=w828-h500-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>CENTRAL DE ALARME</strong>
            </p>
          </Link>
          <Link
            to="fechaduraeletronica"
            className="a1 p-0 backdrop-opacity-0 cursor-pointer  rounded-md backdrop-invert blocos "
          >
            <img
              alt="fechaduraeletronica"
              className="  p-1 w-full h-56 rounded-lg "
              src="https://lh3.googleusercontent.com/pw/AL9nZEVHKLQQZJENSkHnel9MaP99AG5FSXaGjwqtuot2cweZ_oZqUetkFF0cIsNdSzPHkAFU1e-wYLtmNmBkOHchqPATjwBC10zR2U7FbhGoUNQnPIcOLTgU27uxOExQJSGtBmQh47cI04ZfDhU538zaib54=s300-no"
            />
            <p className="text-center text-sm break-all m-1">
              <strong>FECHADURA ELETRÔNICA</strong>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
