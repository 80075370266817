import React from 'react';

export default function Manutencao() {
  // const handleOpen = () => {
  //   const none = 'invisible';
  //   const visible = 'visible';
  //   if (none) {
  //     visible;
  //   }
  // };
  window.scrollTo(0, 0);
  return (
    <div className="text-lg  tracking-wide text-black font-sans grid grid-cols-1 gap-2">
      <div className="p-0 backdrop-opacity-0  rounded-md backdrop-invert blocos ">
        <div className="p-1 grid gap-2 md:grid-cols-1 xl:grid-cols-1 ">
          <div className="flex bg-black rounded-md ">
            <div className="flex justify-center pt-44 z-20 w-full h-56 absolute ">
              <span className="md:text-3xl m-0  text-white ">
                <strong>DESENVOLVIMENTO DE SITES E APPS</strong>
              </span>
            </div>
            <div className="w-full flex justify-center ">
              <img
                alt="DESENVOLVIMENTO DE SITES E APPS"
                className=" opacity-70 p-0 md:w-7/12  h-56 "
                src="https://lh3.googleusercontent.com/pw/AL9nZEU_RTbCjLzUBzX6OufiRNKY0vbQR8KQ0wV5cHvX7t-MYmpSr70pUwmimfcQFsvxiPVoLbtgfWc1GbfKkfwramF6Pchf_PmjUHNcxX9dyBTUJdX3bg2loXPcIEDb0Wu0T_Xq5rfEJijTXvZz10FfhnU3=w657-h412-no"
              />
            </div>
          </div>
          <div className="m-2 whitespace-nomal leading-relaxed tracking-wide  indent-4">
            <h1 className="mr-0  text-base md:text-lg">
              A <strong>CONSERTE LAGOS TECNOLOGIA</strong> acredita que
              atualmente, o website é um cartão de visita importantíssimo para
              qualquer empresa. A criação de site ou APP, tem o propósito de
              trazer novos clientes e valor agregado para sua empresa ou
              negócio, tornando mais competitivo e mais atualizado em relação
              aos clientes e consumidores do mercado moderno, afinal, hoje em
              dia a maioria das pessoas faz buscas na internet sobre a empresa,
              produto ou serviço que está prestes a contratar. Essa é a forma
              correta para se obter sucesso em vendas com um site institucional
              ou um site de vendas e de propagar sua marca pela internet.{' '}
              <a href="https://api.whatsapp.com/send?phone=5522997681134">
                <strong className="animate-pulse">
                  Entre em contato e saiba mais
                </strong>
                .
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
