/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from './styled';
import { Nav } from '../Header/styled';

const Base = () => {
  const dat = new Date();
  const data = dat.getFullYear();

  return (
    <Footer>
      <Nav className="w-full backdrop-blur flex-none rounded-md grid md:grid-cols-3 divide-y-8 md:divide-y-0 md:divide-x-2 gap-2  ">
        {' '}
        <div className="text-sm text-center">
          <Link to="/QuemSomos" className="  ">
            Sobre a Conserte Lagos
          </Link>
        </div>
        <div className="text-sm text-center">
          <a rel="bBas" href="" className=" " target="_blank">
            Contato e Anúncio
          </a>
        </div>
        <div className="text-sm text-center">
          <a rel="bBas" href="termos.html" className=" " target="_blank">
            Termos de Privacidade
          </a>
        </div>
      </Nav>{' '}
      <div className="text-center font-extrabold text-slate-700/40 mt-3 select-none">
        © {data} Conserte Lagos - Todos os Direitos Reservados{' '}
      </div>{' '}
    </Footer>
  );
};
export default Base;
